@import url('https://fonts.googleapis.com/css2?family=Rock+Salt&family=Trykker&display=swap');

:root {
  --main_: #4a2fa0ff;
  --white_: #fff2ccff;
  --accent_: #5d3cc6ff;
  --black_: #151518ff;
}

body {
  margin: 10%;
  margin-top: 1%;
  margin-bottom: 5%;
  font-family: Trykker;
  line-height: 30px;
  color: var(--white_);
  background: var(--main_);
  background-size: 1000% 1000%;
	/* -webkit-animation: gradientBG 40s ease-in-out infinite;
	        animation: gradientBG 40s ease-in-out infinite; */
}

::-moz-selection {
  color: var(--main_);
  background: var(--white_);
}

::selection {
  color: var(--main_);
  background: var(--white_);
}

.App {
  margin: 0;
  padding: 0;
  padding-top: 30px;
  /* transition: all 0.5s ease; */
}

.fontFix {
  font-family: Rock Salt;
}

hr {
  margin-top: 3%;
  margin-bottom: 1%;
  opacity: 10%;
}

h1 {
  /* font-size: 50px; */
  font-family: Rock Salt;
  background: var(--white_);
  color: var(--main_);
  padding: 10px;
  text-align: center;
  border-radius: 20px;
}

@media only screen and (min-width: 600px) {
  h1 {
    font-size: 15px;
    /* width: 7%; */
    background: #fff2cc00;
    color: var(--white_);
  }
}

#mainText {
  font-family: Rock Salt;
  text-align: justify;
  text-justify: inter-word;
  break-word: break-all;
  margin-bottom: 5%;
}

#artist {
  font-size: 20px;
  font-family: Rock Salt;
}

#artist-bobek {
  font-size: 17px;
  font-family: Rock Salt;
}

@media only screen and (max-width: 600px) {
  #mainText {
    line-height: 40px;
    font-size: 15px;
  }
}

@media only screen and (min-width: 600px) {
  #mainText {
    line-height: 80px;
    font-size: 15px;
  }
  #artist {
    font-size: 35px;
  }
  #artist-bobek {
    font-size: 17px;
  }
}

.subText {
  font-size: 23px;
  text-align: center;
}

.subSubText {
  font-size: 13px;
  text-align: center;
}

.opacityText {
  opacity: 45%;
}

.opacityText:hover {
  opacity: 100% !important;
}

.nobr { white-space: nowrap; }

/* links */

a, .link {
  color: var(--white_);
  text-decoration-style: wavy;
  text-decoration-thickness: 6%;
  cursor: pointer;
}

a:hover {
  text-decoration-thickness: 8%;
  font-style: italic;
}

#coffee {
  width: 150px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2%;
  margin-bottom: 2%;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  /* box-shadow: 10px 10px 30px 8px #00000088; */
  /* border: 3px dotted var(--white_);   */
}

@-webkit-keyframes gradientBG {
    0% {
        background: var(--main_);
    }
    40% {
        background: var(--main_);
    }
    60% {
        background: var(--accent_);
        --main_: var(--accent_);
      }
    80% {
        background: var(--black_);
        --main_: var(--black_);
    }
    100% {
      background: var(--main_);
  }
}

@keyframes gradientBG {
  0% {
    background: var(--main_);
  }
  40% {
      background: var(--main_);
  }
  60% {
      background: var(--accent_);
      --main_: var(--accent_);
  }
  80% {
      background: var(--black_);
      --main_: var(--black_);
  }
  100% {
    background: var(--main_);
  }
}

@keyframes animateCoffee {
  0% { transform: translate(0px, 0px) rotate(0deg); }
  20% { transform: translate(-3px, -20px) rotate(1deg); }
  40% { transform: translate(1px, -1px) rotate(11deg); opacity: 100%; }
  50% { transform: translate(100px, 300px) rotate(7deg); opacity: 20%;}
  60% { transform: translate(0px, 1px) rotate(180deg); opacity: 100%; }
  80% { transform: translate(-100px, 400px) rotate(-4deg); opacity: 20%;}
  90% { transform: translate(0px, -200px) rotate(0deg); opacity: 100%;}
  100% { transform: translate(0px, 0px) rotate(180deg); }
}

.langSwitch {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px;
  padding-left: 0px;
  z-index: 3;
  position: fixed;
  bottom: 10px;
  left: 0;
  backdrop-filter: blur(4px);
  border-radius: 0px 20px 20px 0px;
}

.shownSwitcher {
  box-shadow: 10px 10px 30px 8px #00000088;
}

.langSwitcher {
  opacity: 70%;
  border: none;
  color: var(--white_);
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 2px;
  border-radius: 5px;
}

.langSwitcher.link {
  box-shadow: 2px 2px 10px 1px var(--black_);
  cursor: pointer;
}

.langSwitch:hover .langSwitcher {
  opacity: 100%;
}

.langSwitcher.link:hover {
  opacity: 100%;
  box-shadow: 10px 10px 30px 8px var(--white_) inset;
  color: var(--main_);
}

.hider {
  color: var(--main_);
  background-color: var(--white_);
  border: 2px solid var(--white_);
  margin-left: -10px;
}

.active {
  background: var(--white_);
}

.doubleFlex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  align-content: flex-start;
}

#map {
  border: 1px solid #000000;
  border-radius: 20px;
  box-shadow: 10px 10px 30px 4px #00000088;
  margin-top: 5%;
}

.tooltip {
  position: relative;
  display: flex;
  justify-content: center;
  margin: auto;
}

.tooltip > p {
  border-bottom: 3px dotted var(--white_);
  /* opacity: 80%; */
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: var(--white_);
  color: var(--main_);
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  line-height: normal;
  position: absolute;
  z-index: 1;
  bottom: -100%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  cursor: default;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent var(--white_) transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 0.9;
}

table {
  /* border-collapse: collapse; */
  width: 75%;
}

td, th {
  padding: 8px;
  border-radius: 20px;
}

tr:nth-child(even) {
  background-color: #15151809
}

tr:nth-child(odd) {
  background-color: #15151805
}

tr:hover {
  background-color: #15151844; 
}

thead {
  background-color: #15151844;
}

tr:first-child td:first-child { border-top-left-radius: 20px; }
tr:first-child td:last-child { border-top-right-radius: 20px; }

tr:last-child td:first-child { border-bottom-left-radius: 20px; }
tr:last-child td:last-child { border-bottom-right-radius: 20px; }

th #artist {
  font-family: Trykker;
}

.swiper {
  margin: 2%;
  width: 100%;
  height: 500px;
  border-radius: 20px;
  box-shadow: 10px 10px 30px 4px #00000088;
  background: url(./assets/coffe-time-minify.svg);
  background-repeat: repeat;
  background-size: 10%;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  align-items: center
}

.sliderText {
  color: var(--white_);
  font-size: 30px;
  padding: 12px;
  background: var(--main_);
  position: absolute;
  margin: 10%;
  bottom: 0;
  width: min-content;
}

.sliderTextSmall {
  color: var(--white_);
  font-size: 10px;
  font-family: Trykker;
  background: var(--main_);
  line-height: 1.1;
  position: absolute;
  margin-left: 10%;
  margin-bottom: 5%;
  bottom: 0
}

a > img:hover {
  opacity: 80%;
}

.map-responsive{
    /* overflow:hidden; */
    /* padding-bottom: 100%; */
    /* position:relative; */
    /* height:0; */
    width: 100%;
    heigh: 100%;
}

.map-responsive iframe{
    left:0;
    top:0;
    /* height:100%; */
    width:100%;
    /* position:absolute; */
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--white_) var(--main_);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: var(--main_);;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--white_);
  border-radius: 40px;
  border: 3px solid var(--main_);
}

#fbBanner {
  position: fixed;
  top: 0%;
  right: 0%;
  left: 0%;
  color: var(--main_);
  background-color: var(--white_);
  z-index: 100;
  display: flex;
  justify-content: space-between;
  padding: 1px;
  align-items: center;
  flex-direction: row;
}

#fbBanner > span > a {
  color: var(--main_);
}

#fbBanner > span {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

#fbBanner > button {
  border: 2px solid var(--white_);
  border-radius: 20px;
  width: 30px;
  height: 30px;
}

#dropdownMenu {
  padding-right: 10px;
  padding-left: 10px;
  box-shadow: 8px 8px 30px 5px #00000088;
  border-radius: 20px;
  backdrop-filter: blur(4px);
  align-items: center;
  display: flex inline-block;
  flex-direction: row;
  justify-content: center;
  width: fit-content;
  
}

#dropdownMenu > button {
  border: 0px;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  padding: 4px;
  margin-left: 10px;
  /* background-color: var(--white_); */
  backdrop-filter: blur(4px);
  cursor: pointer;
}

.modal-section {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(6px) brightness(80%);
  z-index: 100;
  /* display: none; */
  justify-content: center;
  align-items: center;
}

#radiokapitalembed {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  padding: 2%;
  box-shadow: 10px 10px 30px 4px #00000088;
  background-color: var(--white_);
}